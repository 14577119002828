import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import RkdCard from "../../../app/component/card/RkdCard";
import QuarterlyDataGrid from "./QuarterlyDataGrid";
import { useStore } from "../../../app/stores/store";
import { MenuProps } from "../../geological/new/GeologicalPageCreate";
import { IObjectOptions } from "../../../app/models/object";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import QuarterlyChart from "./components/QuarterlyChart";
import { format } from "date-fns";

const IDENTIFIER = {
  RIGHT: "right",
  LEFT: "left",
  WELL: "well",
  START_DATE: "startDate",
  END_DATE: "endDate",
};

export default function QuarterlyReport() {
  const { quarterlyStore, objectStore, commonStore } = useStore();
  const { getQuarterlyAxes, quarterlyChartLeftYAxes, quarterlyChartRightYAxes, getQuarterlyChart, quarterlyCharts } = quarterlyStore;
  const [date, setDate] = useState<Date[]>([new Date(), new Date()]);
  const [objectWellList, setObjectWellList] = useState<IObjectOptions[]>([]);
  const [selectedMultipleLeftYAxis, setSelectedMultipleLeftYAxis] = useState<string[]>([]);
  const [displaySelectedLeftYAxis, setDisplaySelectedLeftYAxis] = useState<string[]>([]);
  const [selectedMultipleRightYAxis, setSelectedMultipleRightYAxis] = useState<string[]>([]);
  const [displaySelectedRightYAxis, setDisplaySelectedRightYAxis] = useState<string[]>([]);
  const [selectedMultipleWell, setSelectedMultipleWell] = useState<string[]>([]);
  const [displaySelectedMultipleWell, setDisplaySelectedMultipleWell] = useState<string[]>([]);
  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => setValue(newValue);

  const isAllWellSelected = objectWellList.length > 0 && selectedMultipleWell.length === objectWellList.length;
  const isAllLeftYAxisSelected = quarterlyChartLeftYAxes.length > 0 && selectedMultipleLeftYAxis.length === quarterlyChartLeftYAxes.length;
  const isAllRightYAxisSelected = quarterlyChartRightYAxes.length > 0 && selectedMultipleRightYAxis.length === quarterlyChartRightYAxes.length;

  const handleSelectYAxis = async (event: SelectChangeEvent<typeof selectedMultipleLeftYAxis>, identifier: string) => {
    const { value } = event.target;
    let result: string[] = [];

    switch (identifier) {
      case IDENTIFIER.RIGHT:
        if (value[value.length - 1] === "all") {
          setSelectedMultipleRightYAxis(
            selectedMultipleRightYAxis.length === quarterlyChartRightYAxes.length ? [] : quarterlyChartRightYAxes.map((item) => item.value)
          );
          setDisplaySelectedRightYAxis(quarterlyChartRightYAxes.map((item) => item.text));
          return;
        }

        quarterlyChartRightYAxes.forEach((val) => value.includes(val.value) && result.push(val.text));
        setDisplaySelectedRightYAxis(result);
        setSelectedMultipleRightYAxis(typeof value === "string" ? value.split(",") : value);
        break;
      case IDENTIFIER.LEFT:
        if (value[value.length - 1] === "all") {
          setSelectedMultipleLeftYAxis(
            selectedMultipleLeftYAxis.length === quarterlyChartLeftYAxes.length ? [] : quarterlyChartLeftYAxes.map((item) => item.value)
          );
          setDisplaySelectedLeftYAxis(quarterlyChartLeftYAxes.map((item) => item.text));
          return;
        }

        quarterlyChartLeftYAxes.forEach((val) => value.includes(val.value) && result.push(val.text));
        setDisplaySelectedLeftYAxis(result);
        setSelectedMultipleLeftYAxis(typeof value === "string" ? value.split(",") : value);
        break;
      case IDENTIFIER.WELL:
        if (value[value.length - 1] === "all") {
          setSelectedMultipleWell(selectedMultipleWell.length === objectWellList.length ? [] : objectWellList.map((item) => item.value));
          setDisplaySelectedMultipleWell(objectWellList.map((item) => item.text));
          return;
        }

        objectWellList.forEach((val) => value.includes(val.value) && result.push(val.text));
        setDisplaySelectedMultipleWell(result);
        setSelectedMultipleWell(typeof value === "string" ? value.split(",") : value);
        break;
      default:
        break;
    }
  };

  const onChangeDate = (newDate: Date, identifier: string) => {
    let temp = [...date];

    if (identifier === IDENTIFIER.START_DATE) temp[0] = newDate;
    if (identifier === IDENTIFIER.END_DATE) temp[1] = newDate;

    setDate(temp);
  };

  useEffect(() => {
    getQuarterlyAxes(IDENTIFIER.LEFT);
    getQuarterlyAxes(IDENTIFIER.RIGHT);
    objectStore.getObjectOptions("Well").then((res) => {
      setObjectWellList(res);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedMultipleLeftYAxis.length || !selectedMultipleRightYAxis.length) return;

    let payload = {
      wellIds: selectedMultipleWell,
      startYear: format(new Date(date[0]), "yyyy"),
      endYear: format(new Date(date[1]), "yyyy"),
      leftYAxisValues: selectedMultipleLeftYAxis.map((item, index) => ({
        value: item,
        text: displaySelectedLeftYAxis[index],
      })),
      rightYAxisValues: selectedMultipleRightYAxis.map((item, index) => ({
        value: item,
        text: displaySelectedRightYAxis[index],
      })),
    };
    getQuarterlyChart(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedMultipleLeftYAxis, selectedMultipleRightYAxis, selectedMultipleWell]);

  useEffect(() => {
    commonStore.setTitlePage("Quarterly Page Report");
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <RkdCard title="Quarterly Report" subtitle="Quarterly Report Page">
          <Grid sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "12px" }}>
            <Box sx={{ width: "100%", display: "flex", gap: "12px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Start Date"
                  value={date[0]}
                  views={["year"]}
                  inputFormat="yyyy"
                  onChange={(e: any) => onChangeDate(e, IDENTIFIER.START_DATE)}
                  renderInput={(props) => <TextField required fullWidth name={IDENTIFIER.START_DATE} {...props} />}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="End Date"
                  value={date[1]}
                  views={["year"]}
                  inputFormat="yyyy"
                  onChange={(e: any) => onChangeDate(e, IDENTIFIER.END_DATE)}
                  renderInput={(props) => <TextField required fullWidth name={IDENTIFIER.END_DATE} {...props} />}
                />
              </LocalizationProvider>
            </Box>

            <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end", gap: "12px" }}>
              <FormControl sx={{ width: "180px", justifyContent: "flex-end" }}>
                <InputLabel id="demo-multiple-checkbox-label">Well Name</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleWell}
                  onChange={(e) => handleSelectYAxis(e, IDENTIFIER.WELL)}
                  input={<OutlinedInput label="Well Name" />}
                  renderValue={() => displaySelectedMultipleWell.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllWellSelected}
                      indeterminate={selectedMultipleWell.length > 0 && selectedMultipleWell.length < objectWellList.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {objectWellList.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleWell.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "180px", justifyContent: "flex-end" }}>
                <InputLabel id="demo-multiple-checkbox-label">Left YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleLeftYAxis}
                  onChange={(e) => handleSelectYAxis(e, IDENTIFIER.LEFT)}
                  input={<OutlinedInput label="Left YAxes" />}
                  renderValue={() => displaySelectedLeftYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllLeftYAxisSelected}
                      indeterminate={selectedMultipleLeftYAxis.length > 0 && selectedMultipleLeftYAxis.length < quarterlyChartLeftYAxes.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {quarterlyChartLeftYAxes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleLeftYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "180px", justifyContent: "flex-end" }}>
                <InputLabel id="demo-multiple-checkbox-label">Right YAxes</InputLabel>
                <Select
                  multiple
                  value={selectedMultipleRightYAxis}
                  onChange={(e) => handleSelectYAxis(e, IDENTIFIER.RIGHT)}
                  input={<OutlinedInput label="Right YAxes" />}
                  renderValue={() => displaySelectedRightYAxis.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllRightYAxisSelected}
                      indeterminate={selectedMultipleRightYAxis.length > 0 && selectedMultipleRightYAxis.length < quarterlyChartRightYAxes.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {quarterlyChartRightYAxes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      <Checkbox checked={selectedMultipleRightYAxis.indexOf(item.value) > -1} />
                      <ListItemText primary={item.text} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Box>
            {!!selectedMultipleWell.length ? (
              <Box>
                <Divider sx={{ mt: "6px", mb: "24px" }} />
                <Typography variant="h2" sx={{ textAlign: "center" }}>
                  Chart Reports
                </Typography>
                <Box sx={{ width: "100%", typography: "body1", mb: "24px", mt: "12px" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {displaySelectedMultipleWell?.map((item: string, index: number) => (
                          <Tab label={item} value={(index + 1).toString()} />
                        ))}
                      </TabList>
                    </Box>
                    {quarterlyCharts.map((item: any, index: number) => (
                      <TabPanel sx={{ color: "black" }} value={(index + 1).toString()}>
                        <QuarterlyChart quarterlyChart={item} />
                      </TabPanel>
                    ))}
                  </TabContext>
                </Box>
              </Box>
            ) : undefined}
          </Box>
        </RkdCard>
      </Grid>
    </Grid>
  );
}
